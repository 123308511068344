<script>
//import simplebar from "simplebar-vue"

import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";

import axios from "axios";

import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Korean} from "flatpickr/dist/l10n/ko.js"

import {AgGridVue} from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import $ from 'jquery'
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

'./imageEditorTheme'

/**
 * Starter-page component
 */
export default {
  page: {
    title: "알림톡",
    meta: [
      {
        name: "알림톡",
        /*content: appConfig.description,*/
      },
    ],
  },
  data() {
    return {
      title: "알림톡",
      items: "선택된 고객에게 메시지를 전송합니다.",
      talkList: [],
      sendSelect: 'now',
      sendNow: new Date(),
      resvTime: '13:00',
      resvDate: new Date(),
      /*new Date().toJSON().slice(0, 10)*/
      sendMemberList: [],
      timeOptions: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i",
      },
      config: {
        enableTime: false,
        minDate: 'today',
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: '#',
          field: 'mk_custom_srl',
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {headerName: '고객명', field: 'name'},
        {headerName: '연락처', field: 'phone'}
      ],
      remainPoint: '',
      talkPrice: '',
      templateList: [],
      templateContent: '',
      inputList: [],
      templateVar: '',
      templateSrl: '',
    };
  },
  components: {
    Layout,
    //simplebar,
    AgGridVue,
    PageHeader,
    flatPickr,
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  mounted() {
    this.callTemplete()

    this.callTalkMember()
    this.getRemainPoint()

    //템플릿 전송
    $('#sendKaTalk').click(() => {
      let tmpList = []

      $('#previewContent input').each(function () {
        //console.log("태그명 : " + this.tagName + ", type 속성명 : " + $(this).attr('type') + " / " + this.id + '\n');
        //console.log('value = ' + $(`#${this.id}`).val());

        tmpList.push({
          key: this.id,
          value: $(`#${this.id}`).val()
        })
      });

      let patten = /#.+?\}/g;
      let matchTmp = this.templateVar.match(patten)

      //console.log(this.templateVar)
      this.getSelectedRowData()

      if (this.sendMemberList.length == 0) {
        this.$bvModal.msgBoxOk('선택된 수신자가 없습니다.\n수신자를 선택해주세요.', msgModalOptCenter)
        return
      }

      if (this.templateVar == '') {
        this.$bvModal.msgBoxOk('선택된 템플릿이 없습니다.\n템플릿을 선택해주세요.', msgModalOptCenter)
        return
      }

      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value == '') {
          this.$bvModal.msgBoxOk('메시지 내용에 입력되지 않은 부분이 있습니다.\n확인해주세요!', msgModalOptCenter)
          return
        }
      }

      this.$bvModal.msgBoxConfirm(`[${this.sendMemberList.length}]명의 수신자에게 전송하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              try {
                for (let i = 0; i < matchTmp.length; i++) {
                  this.templateVar = this.templateVar.replaceAll(`${matchTmp[i]}`, `${tmpList[i].value}`)
                }
              } catch {
                console.log('already Change')
              }

              let url = `/api/kakao/A/v2`

              let param = {
                'temp_srl': this.templateSrl,
                'template': this.templateVar,
                'rsrv_time': this.selectSendTime(),
                'customlist': this.sendMemberList.toString(),
              }
              //console.log(param)

              axios.post(url, param)
                  .then((res) => {
                    console.log(res.data)

                    if (res.data.status == 'success') {
                      this.$bvModal.msgBoxOk('알림톡 전송 완료!', msgModalOptCenter)
                    } else {
                      this.$bvModal.msgBoxOk('알림톡 전송 실패하였습니다.', msgModalOptCenter)
                    }

                    this.getRemainPoint()
                  })
                  .catch(err => console.log(err))
            }
          })

      /*else {
        try {
          for (let i = 0; i < matchTmp.length; i++) {
            this.templateVar = this.templateVar.replaceAll(`${matchTmp[i]}`, `${tmpList[i].value}`)
          }
        } catch {
          console.log('already Change')
        }

        console.log(this.templateVar)
      }*/

    })
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  methods: {
    //Ag-Grid 관련 Methods
    onFirstDataRendered(event) {
      event.api.sizeColumnsToFit()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
    getSelectedRowData() {
      let selectNodes = this.gridApi.getSelectedNodes()
      let selectData = selectNodes.map(node => node.data)

      var list = []
      selectData.forEach(function (item) {
        list.push(item.mk_custom_srl)
      })
      this.sendMemberList = list
      //console.log(this.sendMemberList.toString())
    },

    //수신자 리스트 호출
    callTalkMember() {
      /*let url = "/api/kakao/custom/306"*/

      let url = `/api/kakao/custom/${this.logged_info.mart_srl}`
      axios.get(url).then((response) => {
        //console.log(response.data[0])

        this.talkList = response.data
      })
    },

    //보유포인트 조회
    getRemainPoint() {
      let url = `/api/kakao/payment/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.remainPoint = Math.round(res.data.point)
        this.talkPrice = res.data.talk_price
      }).catch(err => console.log(err))
    },

    //템플릿 가져오기
    callTemplete() {
      let url = `/api/kakao/template/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)
        this.templateList = res.data
      }).catch(err => console.log(err))
    },

    //템플릿 고르기
    selectTemplete(val) {
      //console.log('selectTemplete', val)

      this.$bvModal.hide('modal-center')

      this.templateVar = val.template_var
      this.templateContent = val.template_var.replaceAll('\n', '<br />')
      this.templateSrl = val.alarmtalk_temp_srl

      let patten = /#.+?\}/g;
      let matchTmp = this.templateContent.match(patten)

      for (let i = 0; i < matchTmp.length; i++) {
        this.inputList.push(
            {
              key: `sendInput${i}`,
              value: ''
            }
        )
        this.templateContent = this.templateContent.replaceAll(`${matchTmp[i]}`, `<input type="text" class="form-control form-control-sm" style="width: auto; display: inline" id="sendInput${i}">`)
      }

    },

    //전송 시간 선택하기
    selectSendTime() {
      if (this.sendSelect == 'now') {
        //console.log(this.toStringByFormatting(this.sendNow, new Date()))
        return this.toStringByFormatting(this.sendNow, new Date())
      } else {
        //console.log(this.toStringByFormatting('dateOnly', this.resvDate) + ' ' + this.resvTime)
        //console.log(this.resvDate)

        try {
          return this.toStringByFormatting('dateOnly', this.resvDate) + " " + this.resvTime + ":00"
        } catch {
          return this.resvDate + ' ' + this.resvTime + ':00'
        }
      }
    },

    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },

    //날짜 formating
    toStringByFormatting(select, source) {
      //console.log(new Date().toJSON())
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());

      let hour = source.getHours()
      hour = hour >= 10 ? hour : '0' + hour
      let min = source.getMinutes()
      min = min >= 10 ? min : '0' + min
      let sec = source.getSeconds()
      sec = sec >= 10 ? sec : '0' + sec

      let time = ''

      if (select == 'dateOnly') {
        time = year + '-' + month + '-' + day
      } else if (select == 'link') {
        time = year + month + day
      } else {
        time = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec
      }

      return time
    },

  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">

      <div class="col-xl-3">
        <div
            class="alert alert-warning border-0 d-flex align-items-center"
            role="alert"
            style="box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2)"
            v-if="false"
        >
          <i
              class="uil uil-exclamation-triangle font-size-16 text-warning me-2"
          ></i>
          <div class="flex-grow-1" style="word-break: keep-all">
            계약기간이 <b>21</b>일 남았습니다. (2022.05.09 ~ 2022.05.09 까지)
          </div>
        </div>
        <div
            class="alert alert-warning border-0 d-flex align-items-center"
            role="alert"
            style="box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2)"
        >
          <i
              class="uil uil-exclamation-triangle font-size-16 text-warning me-2"
          ></i>
          <div class="flex-grow-1" style="word-break: keep-all">
            <b>알림톡으로 전송된 링크에 노출되는 상품은 크리톡 상품관리에서 판매중인 상품이 노출됩니다.</b>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div>
              <h5 style="margin: 0;">1. 발송 설정</h5>
            </div>
            <hr class="my-3"/>

            <div style="padding-bottom: 15px;">
              <div class="row"
                   style="display: flex; align-items: center; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); height: 50px; border-radius: 0.25rem;">
                <div class="form-check" style="margin: 14px;">
                  <input
                      class="form-check-input"
                      type="radio"
                      name="send"
                      id="send1"
                      v-model="sendSelect"
                      value="now"
                      checked
                  />
                  <label class="form-check-label" for="send1">
                    즉시발송
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div class="row"
                   style="display: flex; align-items: center; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); border-radius: 0.25rem;">
                <div class="form-check" style="margin: 14px;">
                  <input
                      class="form-check-input"
                      type="radio"
                      name="send"
                      id="send2"
                      value="res"
                      v-model="sendSelect"
                  />
                  <label class="form-check-label" for="send2">
                    예약발송
                  </label>
                </div>

                <div v-if="sendSelect === 'res'" style="margin-top: 0; padding: 14px; background-color: #f3f3f3">
                  <table>
                    <thead>
                    <tr>
                      <td style="width: 50%;">
                        <feather type="calendar" style="width: 14px; height: 14px; margin-right: 6px"/>
                        날짜
                      </td>
                      <td>
                        <feather type="clock" style="width: 14px; height: 14px; margin-right: 6px"/>
                        시간
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <flat-pickr
                            v-model="resvDate"
                            :config="config"
                            placeholder="발송일"
                            class="form-control"
                        ></flat-pickr>
                      </td>
                      <td>
                        <flat-pickr
                            v-model="resvTime"
                            :config="timeOptions"
                            class="form-control"
                        />
                        <!--<input type="text" class="form-control timepicker" id="resrv_time" name="resrv_time" placeholder="시간" onkeypress="checkInputTime(event);" onfocus="blur();">-->
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="text-left" style="padding:20px;">
            <p style="color:#e85f2a">보유 포인트</p>
            <h2 style="margin:0; color:#333;">{{ remainPoint | comma }}</h2>
            <img :src="require('@/assets/mcimages/ic_right.svg')" alt=""
                 style="position: absolute; right: 30px; top:45px; width: 3%;"
            />
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="info-box-content">
              <p style="color:#e85f2a">건별 금액</p>
              <h2 style="margin:0; color:#333;">{{ talkPrice }}</h2>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="text-left" style="padding:20px;">
            <p style="color:#e85f2a">총 수신자 수</p>
            <h2 style="margin:0; color:#333;">{{ talkList.length }}</h2>
            <img :src="require('@/assets/mcimages/ic_right.svg')" alt=""
                 style="position: absolute; right: 30px; top:45px; width: 3%;"
            />
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="info-box-content">
              <p style="color:#e85f2a">전송가능 인원</p>
              <h2 style="margin:0; color:#333;">{{ Math.floor(remainPoint / talkPrice) }}</h2>
            </div>
          </div>
        </div>
      </div>


      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="row" style="display: flex; align-items: center; margin-bottom: -10px;">
              <div class="col-lg-7" style="display: inline-block; vertical-align: middle; float: none;">
                <h5 style="margin: 0;">2. 수신자 설정 ({{ talkList.length }})</h5>
              </div>
              <div class="col-lg-5">
                <input
                    class="form-control"
                    type="text"
                    ref="default_input"
                    placeholder="수신자 검색"
                    @input="onFilterTextBoxChanged()"
                />
              </div>
            </div>
            <hr class="my-3"/>
            <div style="width: 100%">
              <ag-grid-vue
                  style="height: calc(100vh - 233px); width: 100%; text-align: center;"
                  class="ag-theme-alpine"
                  :columnDefs="columnDefs"
                  :rowData="talkList"
                  rowSelection="multiple"
                  @grid-ready="onGridReady"
                  @first-data-rendered="onFirstDataRendered"
                  :cacheQuickFilter="true"
                  suppressHorizontalScroll="true"
                  enableCellTextSelection="true"
              ></ag-grid-vue>
            </div>
          </div>
        </div>
      </div>


      <div class="col-xl-5">
        <div class="card">
          <div class="card-body">
            <div class="col-lg-6" style="display: inline-block; vertical-align: middle; float: none;">
              <h5 style="margin: 0;">3. 메시지 미리보기</h5>
            </div>
            <hr class="my-3"/>

            <div class="style-talk" style="overflow-x: hidden">
              <div class="talk-header row">
                <div class="col-lg-8">
                  <img :src="require('@/assets/mcimages/asset_talk_header1.png')" alt=""/>
                  <span style="vertical-align: middle">{{ this.logged_info.mart_name }}</span>
                </div>
                <div class="col-lg-4">
                  <img :src="require('@/assets/mcimages/asset_talk_header2.png')" alt="" class="pull-right"
                       style="float: right;"/>
                </div>
              </div>

              <div style="height: calc(100% - 48px); overflow-y: scroll;">
                <div
                    style="margin-left:70px; margin-top:30px; background-color: white; margin-right: 64px; display: block; !important;"
                    class="style-alarm-talk"
                    id="previewContent"
                >
                  <b-modal centered
                           id="modal-center"
                           title="템플릿 선택"
                           body-class="p-0 m-0"
                           ok-title="취소"
                           ok-variant="secondary"
                           ok-only
                  >
                    <div class="modal-body" style="max-height: 650px; overflow-y: scroll">
                      <div class="style-alarm-talk" style="box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2)"
                           v-for="row in templateList"
                           :key="row.alarmtalk_temp_srl"
                           @click="selectTemplete(row)"
                      >
                        <p class="title m-0" style="padding: 1rem; background-color: #ffeb34">
                          {{ row.alarmtalk_temp_srl }}번 템플릿
                          <img :src="require('@/assets/mcimages/img_kakao.png')" alt="kakao">
                        </p>

                        <div style="height:auto;">
                          <div
                              style="width:90%; margin-top:0; background-color: white; border-bottom-left-radius: 16px;">
                            <p class="m-0" style="padding: 1rem;" v-html="row.template.replaceAll('\n', '<br />')"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>

                  <div style="width:100%; text-align: center; position: relative;">
                    <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                         style="position:absolute; left:-57px; top:-30px; width:40px;">
                    <div style="position:absolute; top:-30px;">{{ this.logged_info.mart_name }}</div>
                  </div>

                  <p class="title m-0" style="padding: 1rem;">
                    알림톡 도착
                    <img :src="require('@/assets/mcimages/img_kakao.png')">
                  </p>

                  <div style="height:auto;">
                    <div
                        style="width:100%; margin-top:0; margin-bottom:30px; background-color: white; border-bottom-left-radius: 16px; border-bottom-right-radius: 16px; padding: 1rem;">
                      <div v-html="templateContent"/>

                      <b-button block
                                variant="light"
                                style="width: 100%;"
                                v-b-modal.modal-center
                      >
                        템플릿 선택하기
                      </b-button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
                type="button"
                class="btn btn-block btn-primary btn-lg"
                id="sendKaTalk"
                style="width: 100%; border-radius: 0 0 0.25rem 0.25rem;"
            >
              알림톡 전송하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
/* 알림톡 - 알람톡스타일 */
.style-alarm-talk {
  display: inline-block;
  border-radius: 16px !important;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 20px;
}

.style-alarm-talk p.title {
  background: #ffeb34 !important;
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.style-alarm-talk p.title img {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 34px;
}

/*.style-alarm-talk p {
  padding: 0.5rem;
}*/

/* 알림톡 - 카톡창스타일 */
.style-talk {
  background: #b2c7da !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: calc(100vh - 272px);
  overflow: auto;
  border-radius: 0.25rem;
}

.style-talk .talk-header {
  overflow: hidden;
}

.style-talk .talk-header img {
  height: 48px !important;
}

#talk_preview_content input {
  width: 75%;
  margin-bottom: 10px;
}

#talk_preview_content input:last-child {
  margin-bottom: 0 !important;
}

.btn-default.active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}

/*상품 검색 결과*/
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box-text {
  text-transform: uppercase;
}

.info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}
</style>

<style>
.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}
</style>
